.button, input[type='button'], input[type='reset'], input[type='submit'], .btn{
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin: 0 0 10px 0;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0 36px;
  height: 50px;
  line-height: 48px;
  vertical-align: middle;
  background: transparent;
  font-family: $main_font;
  color: $color-dark;
  text-align: center;
  border-radius: 100px;
  border: 2px solid $color-dark;
  @include single-transition(all , 300ms , ease-in-out);
  &:hover{
    opacity: 1;
    color: $color-light;
    background-color: $color-dark;
    @include single-transition(all , 300ms , ease-in-out);
    transform: translateY(-3px);
  }
  &:focus{
    opacity: 1;
    color: $color-light;
    background-color: $color-dark;
    @include single-transition(all , 300ms , ease-in-out);
    transform: translateY(-3px);
  }
}
.btn--block{
  margin: 0;
  width: 100%;
  min-width: auto;
}
.btn--center{
  margin: 0 auto;
  display: block;
}
.video--btn.btn {
  background-color: transparent;
  border: 0;
  color: #ffffff;
  padding: 0 10px;
  font-size: 18px;
  i{
    float: right;
    font-size: 44px;
    color: inherit;
    padding-left: 7px;
    margin: 0;
    padding-top: 3px;
  }
  @include single-transition(all , 300ms , ease-in-out);
  &:hover{
    color:$color-primary-dark;
    @include single-transition(all , 300ms , ease-in-out);
  }
}

.btn.btn--shadow{
  @include box-shadow(0, 2px ,10px ,0 ,rgba(0, 0, 0, 0.1));
  &:hover{
    @include box-shadow(0 ,2px ,10px,0, rgba(0, 0, 0, 0.0));
  }
}

.btn i{
  float: right;
  font-size: 18px;
  color:inherit;
  padding-left: 7px;
  margin: 0;
  padding-top: 14px;
}
.btn--primary{
  background-color: $color-primary;
  border-color: $color-primary;
  color: $color-light;
  &:hover{
    background-color: $color-dark;
    color: $color-light;
    border-color: $color-dark;
  }
  &:focus{
    background-color: $color-dark;
    color: $color-light;
    border-color: $color-dark;
  }
}
.btn--secondary{
  background-color: $color-secondary;
  border-color: $color-secondary;
  color: $color-light;
  &:hover{
    background-color: $color-dark;
    color: $color-light;
    border-color: $color-dark;
  }
  &:focus{
    background-color: $color-dark;
    color: $color-light;
    border-color: $color-dark;
  }
}
.btn--light__outline{
  border-color: #FFFFFF;
  color: #FFFFFF;
  &:hover{
    color: $color-dark;
    background-color: #FFFFFF;
  }
  &:focus{
    color: $color-dark;
    background-color: #FFFFFF;
  }
}
.btn--light{
  color: $color-dark;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  &:hover{
    border-color: $color-dark;
    color: #FFFFFF;
    background-color: $color-dark;
  }
  &:focus{
    border-color:$color-dark;
    color: #FFFFFF;
    background-color: $color-dark;
  }
}
.btn--dark{
  color:#FFFFFF;
  background-color:$color-dark;
  border-color: $color-dark;
  &:hover{
    border-color: $color-dark;
    color:$color-dark;
    background-color:transparent;
  }
  &:focus{
    border-color: $color-dark;
    color:$color-dark;
    background-color:transparent;
  }
}
.btn--dark__outline{
  border-color: $color-dark;
  color:$color-dark;
  background-color:transparent;
  &:hover{
    color:#FFFFFF;
    background-color:$color-dark;
    border-color: $color-dark;
  }
  &:focus{
    color:#FFFFFF;
    background-color:$color-dark;
    border-color: $color-dark;
   }

}

.facebook:hover, .facebook-bg {
  background: #4b6ea8 !important;
  color: #ffffff !important;
}
.twitter:hover, .twitter-bg {
  background: #4fd5f8 !important;
  color: #ffffff !important;
}
.instagram:hover, .instagram-bg {
  background: #d82950 !important;
  color: #ffffff !important;
}
.pinterest:hover, .pinterest-bg {
  background: #d83633 !important;
  color: #ffffff !important;
}
.linkedin:hover, .linkedin-bg {
  background: #0189c3 !important;
  color: #ffffff !important;
}
.google-plus:hover, .google-plus-bg {
  background: #da2200 !important;
  color: #ffffff !important;
}
.flickr:hover, .flickr-bg {
  background: #0063dc !important;
  color: #fff !important;
}