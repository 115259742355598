/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/
/* Heading Text */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-black;
  font-family:$main_font;
  font-weight: 700;
  margin: 0 0 28px;

}

.h1,
h1 {
  font-size: 48px;
  line-height: 1.3;
}

.h2,
h2 {
  font-size: 38px;
  line-height: 1.4;
}

.h3,
h3 {
  font-size: 32px;
  line-height: 1.5;
}

.h4,
h4 {
  font-size: 28px;
  line-height: 1.5;
}

.h5,
h5 {
  font-size: 18px;
  line-height: 1.6;
}

.h6,
h6 {
  font-size: 14px;
  line-height: 1.7;
}


h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a {
  display:block;
  color:inherit;
}
h1 a:hover, .h1 a:hover,
h2 a:hover, .h2 a:hover,
h3 a:hover, .h3 a:hover,
h4 a:hover, .h4 a:hover,
h5 a:hover, .h5 a:hover,
h6 a:hover, .h6 a:hover{
  text-decoration: none;
}

:active, :focus { outline:0; }

a[href="javascript:void(0)"] {
  cursor: default;
}

p {
  color:$body_color;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7;
}

.lead {
  font-size: 16px;
  line-height: 1.8;
}

/* Aligning Text */
.text--left {
  text-align: left !important;
}

.text--right {
  text-align: right !important;
}

.text--center {
  text-align: center !important;
}

.text--just {
  text-align: justify !important;
}

.align--top {
  vertical-align: top;
}

.align--bottom {
  vertical-align: bottom;
}

.align--middle {
  vertical-align: middle;
}

.align--baseline {
  vertical-align: baseline;
}

/* Weight Text */
.bold {
  font-weight: bold;
}

.regular {
  font-weight: normal;
}

.italic {
  font-style: italic;
}

.break-word {
  word-wrap: break-word;
}

.no-wrap {
  white-space: nowrap;
}

/* Text Color */
.text-white {
  color: #ffffff !important;
}

.text-gray {
  color: #fafaff !important;
}

.text-black {
  color: $color-black;
}

.text-theme {
  color: $color-primary;
}

.text--capitalize {
  text-transform: capitalize !important;
}

.text--uppercase {
  text-transform: uppercase !important;
}
