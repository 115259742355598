.notfound--panner{
  text-align: center;
  margin: 0 auto;
  svg{
    max-width: 500px;
    height: auto;
    margin: 0 auto;
    display: block;
  }

}
.notfound--title{
  font-weight: 300;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 5px;
}
.notfound--text{
  text-align: center;
  font-size: 18px;
}