//call to action

.cta--section{
  width: 100%;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: top right;
  padding: 80px 0;
  background-size: auto;
  .cta--inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .cta--data{
    text-align: left;
    width: 70%;
    display: flex;
    justify-content: space-between;
    i{
      margin-top: -15px;
      font-size: 64px;
      color: $color-light;
      display: inline-block;
    }
    h3{
      margin-bottom: 10px;
      color: $color-light;
    }
    p{
      font-size: 14px;
      color: $color-light;

    }
    .cta--text{
      display: inline-block;
      margin-left: 20px;
    }
  }
  .cta--btn{
    width: 30%;
    text-align: right;
  }
  @include phone{
    background-size: cover;
    .cta--inner{
      display: block;
      align-items: center;
      justify-content: space-between;
    }
    .cta--data{
      text-align: center;
      width: 100%;
      display: block;
      i{
        margin-top: 5px;
      }
      .cta--text{
        margin-left:0;
      }
    }
    .cta--btns{
      width: 100%;
      text-align: center;
    }
  }
}

