
.footer {
  background-color: $color-black;
  padding: 120px 0;
  min-height: 150px;
  position: relative;
  .footer--social{
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    float: none;
    position: relative;
    li{
      display: inline-block;
      padding: 5px 5px;
      text-align: center;
      a{
        display: block;
        overflow: hidden;
        svg, svg path{
          max-width: 100%;
          overflow: hidden;
          fill: #ffffff;
          @include single-transition(all,ease-in-out,0.4s);
        }
        &:hover{
          svg, svg path{
            @include single-transition(all,ease-in-out,0.4s);
            fill:$color-primary-dark;
          }
        }
      }
    }
  }
  .footer--copy{
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1em;
    color: #ffffff;
    margin-top: 15px;
    font-family:$main_font;
    text-transform: capitalize;
  }
}

.footer--fixed {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 0;
}

.social-lists li a {
  font-size: 16px;
  height: 40px;
  width: 40px;
  line-height: 1.875rem;
  border-radius: 100px;
  background: #545f75;
  border:solid 2px #545f75;
  color: #fff;
  text-align: center;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  display: block;
  padding: 7px;
  transform: translateY(0);
  @include single-transition(all,0.3s, ease-in-out);
  &:hover{
    background-color: transparent;
    color: $color-primary;
    border-color: #545f75;
    @include single-transition(all,0.3s, ease-in-out);
    transform: translateY(-3px);
  }
}
