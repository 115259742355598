
/*  ----------------------------------------------------
. PRICING SECTION
-------------------------------------------------------- */
.priceBox {
  text-align: center;
  border-radius: 5px;
  padding: 40px 30px;
  max-width: 350px;
  margin: 25px auto;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.04);
  .priceBox--title{
    font-weight: bold;
    color: $color-black;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .priceBox--value{
    font-size: 24px;
    font-weight: bolder;
    color: #333333;
    margin-bottom: 25px;
    span{
      font-size: 60px;
    }
  }
  .priceBox--list{
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    margin-bottom: 25px;
    li{
      padding: 7px 0;
      font-weight: 500;
      font-size: 14px;
      color: $color-black;
    }
  }
}
.priceBox.featuredBox{
  @include single-transition(all,0.3s, ease-in-out);
  background-color: $color-primary;
  .priceBox--title,.priceBox--value,.priceBox--list li{
    color: $color-light;
  }
}

