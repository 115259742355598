.testimonialbox-one{
  max-width: 750px;
  margin: 0 auto;
  text-align: center;
  background-color: #ffffff;
  padding: 5em;
  border-radius: 10px;
  svg{
    max-width: 75px;
    fill: #222a35;
  }
  .testimonialbox--p{
    color: $color-dark;
    font-size: 16px;
    line-height: 1.7;
    padding: 15px;
    font-weight: 600;
  }
  .testimonialbox--name{
    color: $color_black;
    margin: 15px 0 0;
    text-transform: capitalize;
    span{
      text-transform: capitalize;
      color: $text-gray;
      font-weight: 500;
      font-size: 13px;
      padding: 4px;
    }
  }
  .testimonialbox--img{
    text-align: center;
    margin: 0 auto;
   img{
     margin: 0 auto;
     border-radius: 50px;
     width: 75px;
     border-top-right-radius: 5px;
     border: solid 2px #87909d;
   }
  }
}

.owl-carousel .owl-dots .owl-dot span {
  width: 5px;
  height: 5px;
  margin: 5px;
  background: $color-primary;
  display: block;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-backface-visibility: visible;
  border-radius: 30px;
}
.owl-carousel .owl-dots .owl-dot.active span{
  background-color:$color-primary-dark;
  -webkit-transform: scaleY(1.1);
  transform: scaleY(1.1);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: -30px;
}