@charset "UTF-8";
/*-----------------------------------------------------------------------------------

	Theme Name: Exodia
	Theme URI: https://themeforest.net/user/ra-elements/portfolio
	Description: Exodia is a pixel perfect creative html5 marketing landing page  based on designed with great attention to details, flexibility and performance. It is ultra professional, smooth and sleek, with a clean modern layout.
	Author: ra-elements
	Author URI: https://themeforest.net/user/ra-elements
	Version: 1.0

-----------------------------------------------------------------------------------*/
/*!
// Contents
// ------------------------------------------------>

 1.  Global Styles
 2.  Typography
 3.	 Color
 4.	 Align
 5.	 Grid
 6.	 Background
 7.	 Buttons
 8. Forms
 9. Header
 10. Menu
 11. Hero
 12. Testimonial
 13. Feature
 14. Call To Action
 15. Pricing Tables
 16. Clients
 17. Footer
/*
WARNING! DO NOT EDIT THIS FILE!

To make it easy to update your theme, you should not edit the styles in this file. Instead use
the custom.css file to add your styles. You can copy a style from this file and paste it in
custom.css and it will override the style in this file. You have been warned! :)
*/

/* ----------------IMPORT GOOGLE FONTS ------------------- */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

@import "base/_variables";
@import "base/_typography";

/*------------------------------------*\
    #GLOBAL STYLES
\*------------------------------------*/
@import "base/_global";
@import "components/_forms";

/*  ----------------------------------------------------
2.page heading
-------------------------------------------------------- */
@import "components/_page_heading";


////header
@import "layout/_header";
//
////footer
@import "layout/_footer";
//
////buttons
@import "components/_buttons";
//
// cover section
@import "components/_cover";
//
////section titles
@import "components/_section_titles";
//
////icon boxes
@import "components/_icon_boxes";
//
////img boxes
//@import "components/_img_box";
//
////call to action
@import "components/_call_to_action";
//
////counter
@import "components/_counter";
//
////team
@import "components/_team";
//
////gallery
@import "components/_portfolio";
//
////blog
@import "components/_blog";
//
//testimonial
@import "components/_testimonial";

////price box
@import "components/_price_box";
//
////not found
@import "components/_notfound";
