.portfolio-box--one {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  border-radius: 5px;
  transition: all ease-in-out 0.4s;
  cursor: crosshair;
  .portfolio--img {
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transition: all ease-in-out 0.4s;
    transition: all ease-in-out 0.4s;
  }
  .portfolio--info {
    position: absolute;
    display: block;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    background-color: #fff;
    overflow: hidden;
    text-align: center;
    transition:all ease-in-out 0.4s;
    transform: translateY(100%);
    opacity: 0;
    h5 {
      margin: 25px 0 0;
      color: $color-black;
    }
    p {
      text-transform: capitalize;
      font-size: 13px;
      font-weight: 500;
      color: #5c636c;
    }
  }
  &:hover{
    .portfolio--info{
      transition: all ease-in-out 0.4s;
      transform: translateY(0);
      opacity: 1;
    }
  }
}
.portfolio-box--two{
  cursor: crosshair;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  border-radius: 5px;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  margin-left: auto;
  margin-right: auto;
  .portfolio--img {
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transition: all ease-in-out 0.4s;
    transition: all ease-in-out 0.4s;
  }
  .portfolio--info {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    padding: 30px 40px;
    background-color: rgba($color-primary,0.9);
    -webkit-transition: all ease-in-out 0.4s;
    transition: all ease-in-out 0.4s;
    opacity: 0;
    .portfolio--info--text {
      position: relative;
    }
    h5 {
      color: #ffffff;
      text-transform: capitalize;
      -webkit-transition: all ease-in-out 0.4s;
      transition: all ease-in-out 0.4s;
      -webkit-transition-delay: .2s;
      transition-delay: .2s;
      opacity: 0;
      margin: 25px 0 0;
      text-align: center;
    }
    p {
      text-transform: capitalize;
      font-size: 13px;
      font-weight: 500;
      color: $color-gray;
      -webkit-transition: all ease-in-out 0.4s;
      transition: all ease-in-out 0.4s;
      -webkit-transition-delay: .4s;
      transition-delay: .4s;
      opacity: 0;
    }
  }
  &:hover{
    .portfolio--img {
      /* IE 9 */
      -webkit-transform: scale(1.3);
      /* Safari */
      transform: scale(1.3);
      /* Standard syntax */
      -webkit-transition: all ease-in-out 0.3s;
      transition: all ease-in-out 0.3s;
    }
    .portfolio--info {
      opacity: 1;
    }
    h5 {
      opacity: 1;
      -webkit-transition: all ease-in-out 0.3s;
      transition: all ease-in-out 0.3s;
    }
    p {
      opacity: 1;
      -webkit-transition: all ease-in-out 0.3s;
      transition: all ease-in-out 0.3s;
    }
  }
}
.portfolio-categories {
  float: none;
  text-align: center;
  position: relative;
  display: block;
  margin-bottom: 50px;
  padding: 0;
  li {
    background: $color-primary;
    color: #fff ;
    border-color: $color-primary-dark ;
    margin: 10px 5px;
    padding: 8px 30px;
    border-radius: 50px;
    float: none;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    text-transform: capitalize;
    font-size: 13px;
    font-weight: 600;
    box-shadow: 0 8px 30px rgba(0,0,0,0);
    transition: all .3s ease-in-out;
    &.active,&:hover {
      box-shadow: 0 8px 30px rgba(0,0,0,.2);
      background: #fff;
      color: $color-primary ;
    }
  }
}
