

.form-control {
  font-size: 13px;
  padding: 12px 15px;
  height: 50px;
  border-radius: 50px;
  border: solid 1px #f5f5f5;
  background-color: $color-gray;
  margin-bottom: 25px;
  outline: 0;
  @include single-transition(all,0.3s, ease-in-out);
  &:focus {
    color:$color-dark;
    background-color: #fff;
    border-color: #f7f7f7;
    outline: 0;
    box-shadow: 0 0 0;
    @include single-transition(all,0.3s, ease-in-out);
  }
}

textarea.form-control  {
  height: 180px;
  min-height: 180px;
  border-radius: 10px;
}
 #map{
   height: 100%;
   min-height: 500px;
   width: 100%;
   border: 0;
   box-shadow: 0 0 0;
}

.map_container_aps{
  position: relative;
  height: 100%;
  iframe{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    bottom: 0;
    border-radius: 4px;
  }
}