.counter_box_one {
  text-align: center;
  margin: 20px 0;
  i {
    display: block;
    line-height: 1;
    font-size: 64px;
    color: $color-light;
  }
  .counter {
    font-size: 60px;
    color: $color-primary;
    font-weight: 900;
    margin: 0;
    display: inline-block;
    padding: 0 5px;
    margin-top: 30px;
    line-height: 1em;
  }
  h5 {
    text-transform: capitalize;
    margin: 0;
    margin-top: 25px;
    color: $color-black;
  }
}


.counter_box_two {
  text-align: center;
  margin: 20px 0;
  i {
    display: block;
    line-height: 1;
    font-size: 48px;
    color: #ffffff;
    margin: 0 auto;
    width: 90px;
    height: 90px;
    background-color: $color-primary;
    padding: 20px 10px;
    border-radius: 120px;
    transition: all 0.3s ease-in-out;
    border-bottom-right-radius: 10px;
  }
  .counter {
    font-size: 60px;
    color: $color-light;
    font-weight: 900;
    margin: 0;
    display: inline-block;
    padding: 0 5px;
    margin-top: 30px;
    line-height: 1em;
  }
  h5 {
    text-transform: capitalize;
    margin: 0;
    margin-top: 25px;
    color: $color-black;
  }
}