
//header nav bar

.header{
  z-index: 9;
  background-color: transparent;
  position: fixed;
  width: 100%;
  padding: 0;
  margin-top: 25px;
  @include single-transition(all,ease-in-out,0.4s);
  .logo{
    font-size: 28px;
    font-weight: 700;
    color: $color-light;
    max-width: 110px;
    padding: 0;
    line-height: 1;
    margin: 0;
    @include single-transition(all,ease-in-out,0.4s);
    .sticky-logo{
      display: none;
    }
  }

  li{
    padding: 0;
    a{
      font-size: 14px;
      font-family: $main_font;
      text-transform: capitalize;
      font-weight: 500;
      margin: 0 5px;
      padding: 25px 10px!important;
      line-height: 1.7;
      position: relative;
      display: block;
      color: $color-light;
      @include single-transition(all,ease-in-out,0.4s);
      &:before {
        @include single-transition(all,ease-in-out,0.4s);
          content: "";
          width: 0;
          height: 2px;
          position: absolute;
          bottom: 0;
          left: auto;
          right: 0;
          background: $color-primary;
      }
      &:hover,&.active{
          color: $color-gray;
          @include single-transition(all,ease-in-out,0.4s);
          &:before{
            @include single-transition(all,ease-in-out,0.4s);
            width: 50%;
            left: 0;
            right: 0;
          }
        }
      }
    }
  .header-btns{
    margin-top: 8px;
    margin-left: 10px;
  }
  .btn{
    background-color: transparent;
    border-color: $color-primary;
    color: $color-light;
    &:hover{
      background-color: $color-primary;
      color: $color-light;
      border-color: $color-primary;
    }
    &:focus{
      background-color: $color-primary;
      color: $color-light;
      border-color: $color-primary;
    }
  }
  .btn--search{
    height: 48px;
    border-radius: 5px;
    color: $color-light;
    padding: 14px;
    float: left;
    font-size: 16px;
    margin-right: 10px;
    @include single-transition(all,ease-in-out,0.4s);
    &:hover{
      border-radius: 5px;
      color: $color-primary;
      @include single-transition(all,ease-in-out,0.4s);
    }
  }
}
.sticky_header{
  background-color: #FFFFFF;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.04);
  width: 100%;
  position: fixed;
  top: 0;
  margin-top: 0;
  .btn{
    color: initial;
  }
  .btn--search{
    color: $color-dark;
    @include single-transition(all,ease-in-out,0.4s);
  }
  .logo{
    color: $color-dark;
    @include single-transition(all,ease-in-out,0.4s);
    .sticky-logo{
      display: block;
    }
    .main-logo{
      display: none;
    }
  }
  li{
    a{
      color: $color-dark;
      @include single-transition(all,ease-in-out,0.4s);
      &:hover,&.active{
        color: $color-primary;
        @include single-transition(all,ease-in-out,0.4s);
      }
    }
  }
}

//sub menu in header
.header a.has_sub_menu {
  position: relative;
  padding-right: 15px;
}
.header a.has_sub_menu:after {
  content: "\f107";
  position: absolute;
  right: 0;
  display: inline-block;
  font-size: 14px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: -3px;
  top: 26px;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}
.header a.has_sub_menuu:after {
  font-family: FontAwesome;
  content: "\f105";
  position: absolute;
  right: 0;
  display: inline-block;
  font-size: 18px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  top: 5px;
}
.header li ul.sub_menu {
  background-color: #ffffff;
  border-radius: 3px;
  position: absolute;
  padding: 0;
  min-width: 220px;
  width: auto;
  height: auto;
  margin: 0;
  opacity: 1;
  display: none;
  color: $color-dark;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.04);
  @include single-transition(all,ease-in-out , 0.4s);

}
.header li ul.sub_menu li {
  display: block;
  padding: 0;
}
.header li ul.sub_menu li a.nav-link {
  padding: 8px 15px !important;
  display: block;
  margin-left: 0;
  word-break: break-word;
  color: $color-dark;
  border-bottom: solid 1px #f5f5f5;
  &:hover{
    color: $color-primary;
  }
  &:before {
  content: none;
}
}
.header a.nav-link.active_menu.has_sub_menu:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}
.sub_menu.depth-1{
  right: 0;
  left: 100%;
  top: 0;
}

.header_menu_btn {
  padding: 21px 25px;
  font-size: 22px;
  outline: 0 !important;
  -webkit-box-shadow: 0 0 0 !important;
  box-shadow: 0 0 0 !important;
  cursor: pointer;
  border-radius: 2px;
  position: relative;
  background-color: transparent;
  border: 0;
  display: inline-block;
  text-align: center;
  margin: 0;
}
.header_menu_btn .navbar-toggler-icon {
  width: 20px;
  height: 3px;
  display: block;
  background-color: #080708;
  margin-bottom: 9px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -1.5px;
}
.header_menu_btn .navbar-toggler-icon:before {
  top: -8px;
  background-color: #333333;
  width: 13px;
  height: 3px;
  display: block;
  outline: 1px solid transparent;
  position: absolute;
  content: "";
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}
.header_menu_btn .navbar-toggler-icon:after {
  top: 8px;
  background-color: #333333;
  width: 30px;
  height: 3px;
  display: block;
  outline: 1px solid transparent;
  position: absolute;
  content: "";
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}
@media (min-width: 992px){
.header li:hover > ul {
  display: block;
  visibility: visible;
  @include single-transition(all,ease-in-out , 0.4s);
}}
@media (max-width: 992px){
  .header{
    background-color: #FFFFFF;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.04);
    width: 100%;
    position: fixed;
    top: 0;
    margin-top: 0;
    .btn{
      color: initial;
    }
    .btn--search{
      color: $color-dark;
      @include single-transition(all,ease-in-out,0.4s);
    }
    .logo{
      color: $color-dark;
      @include single-transition(all,ease-in-out,0.4s);
      .sticky-logo{
        display: block;
      }
      .main-logo{
        display: none;
      }
    }
    .menu-header{
      margin-top: 15px;
    }
    li{
      a{
        margin: 0;
        padding: 13px 0 !important;
        line-height: 1.7;
        color: $color-dark;
        @include single-transition(all,ease-in-out,0.4s);
        &:hover,&.active{
          color: $color-primary;
          @include single-transition(all,ease-in-out,0.4s);
        }
        &:before{
          content: none !important;
        }
      }
    }
  }
  .header .container{
    padding: 10px 15px;
  }


  .header li ul.multi_col li {
    display: block;
    width: 100%;
  }

  .header li ul.sub_menu {
    position: static;
    visibility: visible;
    width: 100%;
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
  }
  .header a.has_sub_menu.nav-link:after {
    margin-right: 15px;
    top: 12px;
  }
}
